import { bootstrapApplication } from "@angular/platform-browser";
import { AppComponent } from "./app/app.component";
import { enableProdMode, importProvidersFrom } from "@angular/core";
import { DataModule } from "./app/data/data.module";
import { environment } from "./environments/environment";
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter } from "@angular/router";
import { ROUTES } from "./app/app-routing.module";
import { provideToastr } from "ngx-toastr";
import * as mixpanel from 'mixpanel-browser';
import { NgxSpinnerModule } from "ngx-spinner";


if (environment.production) {
  enableProdMode();
}

mixpanel.init(environment.MIXPANEL_TOKEN, {debug: true, track_pageview: true, persistence: 'localStorage'});
bootstrapApplication(AppComponent, {
  providers: [
    provideToastr(), // Toastr providers
    provideRouter(
      ROUTES
    ),
    importProvidersFrom(
      DataModule,
      NgxSpinnerModule.forRoot({
        type: "square-spin"
      })
    ),
    provideAnimations(),
  ]
})