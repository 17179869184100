import { Mapper } from 'src/app/core/base/mapper';
import { ProvinceZoneDistrictEntity } from '../entities/province-entity';
import { ProvinceZoneDistrictModel } from 'src/app/core/domain/province.model';

export class ProvinceZoneDistrictsRepositoryMapper extends Mapper<
  ProvinceZoneDistrictEntity,
  ProvinceZoneDistrictModel
> {
  mapFrom(param: ProvinceZoneDistrictEntity): ProvinceZoneDistrictModel {
    return {
      districtId: param.districtId,
      districtName: {
        english: param.districtName.en,
        arabic: param.districtName.ar,
      },
    };
  }

  mapTo(param: ProvinceZoneDistrictModel): ProvinceZoneDistrictEntity {
    throw new Error('Method not implemented.');
  }
}
