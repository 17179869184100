import { Injectable } from '@angular/core';
import { AnalyticsRepository } from 'src/app/core/repositories/analytics.repository';
import * as mixpanel from 'mixpanel-browser';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsImplementationRepository extends AnalyticsRepository {
  constructor() {
    super();
  }

  override trackAnalytics(params: { eventName: string; properties?: any; }): void {
    mixpanel.track(`product-checkout-${params.eventName}`, params.properties);
  }
}
