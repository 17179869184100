import { Routes } from '@angular/router';

export const ROUTES: Routes = [
  {
    path: '',
    loadComponent: () => 
      import('./presentation/components/order-checkout/order-checkout.component').then((mod) => mod.OrderCheckoutComponent)
  },
  {
    path: 'result',
    loadComponent: () => 
      import('./presentation/components/success-error-screens/success-error-screens.component').then((mod) => mod.SuccessErrorScreensComponent)
  },
];