import { Observable } from 'rxjs';

import { UseCase } from '../../base/use-case';
import { ProductCheckoutCreateModel } from '../../domain/product-checkout';
import { ProductCheckoutRepository } from '../../repositories/product-checkout.repository';

export class CreateProductCheckoutUseCase implements UseCase<{productCheckoutId: string; data: ProductCheckoutCreateModel}, void> {
  constructor(private _productCheckoutRepository: ProductCheckoutRepository) {}

  execute(params: {productCheckoutId: string; data: ProductCheckoutCreateModel}): Observable<void> {
    return this._productCheckoutRepository.createProductCheckout(params);
  }
}
