import { Observable } from 'rxjs';
import { UseCase } from '../../base/use-case';
import { AnalyticsRepository } from '../../repositories/analytics.repository';

export class TrackAnalyticsUseCase implements UseCase<{eventName: string; properties?: any}, void> {
  constructor(private _analyticsRepository: AnalyticsRepository) {}

  execute(params: {eventName: string; properties?: any}): void {
    return this._analyticsRepository.trackAnalytics(params);
  }
}
