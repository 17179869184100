import { environment } from "src/environments/environment";

export const API_URLS = {
  /** Province */

  PROVINCE_LIST_URL_ALL: (countryCode: string) =>
    `${environment.BACKEND_URL}/countries/${countryCode}/provinces`,
  PROVINCE_ZONES_LIST_URL: (filter: {country: string}, province:string) =>
    `${environment.BACKEND_URL}api/countries/${filter.country}/provinces/${province}/zones`,
  PROVINCE_ZONE_DISTRICTS_LIST_URL: (filter: {country: string}, province: string, zone: string) =>
    `${environment.BACKEND_URL}api/countries/${filter.country}/provinces/${province}/zones/${zone}/districts`,

  /** Product-Checkout */
  GET_PRODUCT_CHECKOUT: (productCheckoutId: string) =>
    `${environment.BACKEND_URL}/product-checkouts/${productCheckoutId}`,

  CREATE_PRODUCT_CHECKOUT: (productCheckoutId: string) =>
    `${environment.BACKEND_URL}/orders/product-checkouts?prodCheckoutId=${productCheckoutId}`,
};