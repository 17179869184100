import { Observable } from 'rxjs';

import { UseCase } from '../../base/use-case';
import { ProductCheckoutModel } from '../../domain/product-checkout';
import { ProductCheckoutRepository } from '../../repositories/product-checkout.repository';

export class GetProductCheckoutUseCase implements UseCase<string, ProductCheckoutModel> {
  constructor(private _productCheckoutRepository: ProductCheckoutRepository) {}

  execute(productCheckoutId: string): Observable<ProductCheckoutModel> {
    return this._productCheckoutRepository.getProductCheckout(productCheckoutId);
  }
}
