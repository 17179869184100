import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {
  ProvinceListEntity,
} from './entities/province-entity';
import { API_URLS } from 'src/app/presentation/shared/constants';

@Injectable({
  providedIn: 'root',
})
export class ProvinceAPIservice {
  constructor(private http: HttpClient) {}

  getAllProvinceZoneDistrictList(country: string): Observable<ProvinceListEntity> {
    return this.http.get<ProvinceListEntity>(API_URLS.PROVINCE_LIST_URL_ALL(country));
  }

}
