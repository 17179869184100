import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import { ProvinceRepository } from 'src/app/core/repositories/province.repository';
import { ProvinceRepositoryMapper } from './mappers/province.mapper';
import { ProvinceAPIservice } from './province-apis.service';
import { ProvinceModel } from 'src/app/core/domain/province.model';

@Injectable({
  providedIn: 'root',
})
export class ProvinceRepositoryImplementation extends ProvinceRepository {
  public provinceMapper = new ProvinceRepositoryMapper();

  constructor(private _provinceAPIService: ProvinceAPIservice) {
    super();
  }

  getAllProvinceZoneDistrictList(country: string): Observable<ProvinceModel[]> {
    return this._provinceAPIService
      .getAllProvinceZoneDistrictList(country)
      .pipe(map((response) => response.data.map(this.provinceMapper.mapFrom)));
  }

}
