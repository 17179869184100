import { Mapper } from 'src/app/core/base/mapper';
import { ProvinceEntity } from '../entities/province-entity';
import { ProvinceZonesRepositoryMapper } from './provinceZones.mapper';
import { ProvinceModel } from 'src/app/core/domain/province.model';

export class ProvinceRepositoryMapper extends Mapper<ProvinceEntity, ProvinceModel> {
  mapFrom(param: ProvinceEntity): ProvinceModel {
    const zoneMapper = new ProvinceZonesRepositoryMapper();
    return {
      shippingRevenue: param.shippingRevenue,
      provinceId: param.provinceId,
      name: {
        english: param.provinceName.en,
        arabic: param.provinceName.ar,
      },
      zones: param.zones?.map(zoneMapper.mapFrom),
    };
  }

  mapTo(param: ProvinceModel): ProvinceEntity {
    throw new Error('Method not implemented.');
  }
}
