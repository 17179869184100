import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { API_URLS } from 'src/app/presentation/shared/constants';
import { ProductCheckoutCreateEntity, ProductCheckoutEntity } from './entities/product-checkout.entity';

@Injectable({
  providedIn: 'root',
})
export class ProductCheckoutAPIservice {
  constructor(private http: HttpClient) {}

  getProductCheckout(productCheckoutId: string): Observable<ProductCheckoutEntity> {
    return this.http.get<ProductCheckoutEntity>(API_URLS.GET_PRODUCT_CHECKOUT(productCheckoutId));
  }

  createProductCheckout(params: {productCheckoutId: string; data: ProductCheckoutCreateEntity}): Observable<void> {
    const filteredData = this.filterData(params.data)
    return this.http.post<void>(API_URLS.CREATE_PRODUCT_CHECKOUT(params.productCheckoutId), filteredData);
  }

  filterData(data: any) {
    const updatedFilter = Object.entries(data)
      .filter(([_, v]) => v != null && v !== '')
      .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});
    return updatedFilter;
  }
}
