import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { CheckoutHeaderComponent } from './presentation/components/order-checkout/checkout-header/checkout-header.component';
import { CheckoutFooterComponent } from './presentation/components/order-checkout/checkout-footer/checkout-footer.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [
    RouterOutlet,
    CheckoutHeaderComponent,
    CheckoutFooterComponent
  ],
})
export class AppComponent implements OnInit {
  constructor(
    @Inject(DOCUMENT) private document: Document
  ){}
  ngOnInit(): void {
    this.document.documentElement.lang = 'ar'; 
    this.document.documentElement.dir = 'rtl'
  }
  title = 'Order Checkout';
}
