import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { ProductCheckoutRepository } from 'src/app/core/repositories/product-checkout.repository';
import { ProductCheckoutAPIservice } from './product-checkout-apis.service';
import {
  ProductCheckoutCreateModel,
  ProductCheckoutModel,
} from 'src/app/core/domain/product-checkout';

@Injectable({
  providedIn: 'root',
})
export class ProductCheckoutImplementationRepository extends ProductCheckoutRepository {
  constructor(private _productCheckoutService: ProductCheckoutAPIservice) {
    super();
  }

  getProductCheckout(
    productCheckoutId: string
  ): Observable<ProductCheckoutModel> {
    return this._productCheckoutService.getProductCheckout(productCheckoutId).pipe(
      map(res => res.data))
  }

  createProductCheckout(params: {
    productCheckoutId: string;
    data: ProductCheckoutCreateModel;
  }): Observable<void> {
    return this._productCheckoutService.createProductCheckout(params);
  }
}
