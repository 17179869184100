import { CommonModule } from '@angular/common';
import { ProvinceRepository } from '../core/repositories/province.repository';
import { GetProvinceListUseCase } from '../core/usecases/province/get-province-list.usecase';
import { ProvinceRepositoryToken } from './injection-tokens/province-repository.inection-token';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ProvinceRepositoryImplementation } from './repositories/province/province-implementation.repository';
import { ProductCheckoutRepositoryToken } from './injection-tokens/product-checkout.inection-token';
import { ProductCheckoutImplementationRepository } from './repositories/product-checkout/product-checkout-implementation.repository';
import { ProductCheckoutRepository } from '../core/repositories/product-checkout.repository';
import { GetProductCheckoutUseCase } from '../core/usecases/product-checkout/get-product-checkout.usecase';
import { CreateProductCheckoutUseCase } from '../core/usecases/product-checkout/create-product-checkout.usecase';
import { AnalyticsRepository } from '../core/repositories/analytics.repository';
import { TrackAnalyticsUseCase } from '../core/usecases/analytics/analytics.usecase';
import { AnalyticsRepositoryToken } from './injection-tokens/analytics.injection-token';
import { AnalyticsImplementationRepository } from './repositories/analytics/analytics-implementation.repository';

const getProvinceListUseCaseFactory = (
  provinceRepository: ProvinceRepository
): GetProvinceListUseCase => new GetProvinceListUseCase(provinceRepository);
export const getProvinceListUseCaseProvider = {
  provide: GetProvinceListUseCase,
  useFactory: getProvinceListUseCaseFactory,
  deps: [ProvinceRepositoryToken],
};

const getProductCheckoutUseCaseFactory = (
  productCheckoutRepository: ProductCheckoutRepository
): GetProductCheckoutUseCase =>
  new GetProductCheckoutUseCase(productCheckoutRepository);
export const getProductCheckoutUseCaseProvider = {
  provide: GetProductCheckoutUseCase,
  useFactory: getProductCheckoutUseCaseFactory,
  deps: [ProductCheckoutRepositoryToken],
};

const createProductCheckoutUseCaseFactory = (
  productCheckoutRepository: ProductCheckoutRepository
): CreateProductCheckoutUseCase =>
  new CreateProductCheckoutUseCase(productCheckoutRepository);
export const createProductCheckoutUseCaseProvider = {
  provide: CreateProductCheckoutUseCase,
  useFactory: createProductCheckoutUseCaseFactory,
  deps: [ProductCheckoutRepositoryToken],
};

const trackAnalyticsUseCaseFactory = (
  analyticsRepository: AnalyticsRepository
): TrackAnalyticsUseCase =>
  new TrackAnalyticsUseCase(analyticsRepository);
export const trackAnalyticsUseCaseProvider = {
  provide: TrackAnalyticsUseCase,
  useFactory: trackAnalyticsUseCaseFactory,
  deps: [AnalyticsRepositoryToken],
};

@NgModule({
  imports: [CommonModule, HttpClientModule],
  providers: [
    getProvinceListUseCaseProvider,
    createProductCheckoutUseCaseProvider,
    getProductCheckoutUseCaseProvider,
    trackAnalyticsUseCaseProvider,
    {
      provide: ProvinceRepositoryToken,
      useClass: ProvinceRepositoryImplementation,
    },
    {
      provide: ProductCheckoutRepositoryToken,
      useClass: ProductCheckoutImplementationRepository,
    },
    {
      provide: AnalyticsRepositoryToken,
      useClass: AnalyticsImplementationRepository,
    },
  ],
})
export class DataModule {}
