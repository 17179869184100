import { Mapper } from 'src/app/core/base/mapper';
import { ProvinceZoneEntity } from '../entities/province-entity';
import { ProvinceZoneDistrictsRepositoryMapper } from './provinceZoneDistrcits.mapper';
import { ProvinceZoneModel } from 'src/app/core/domain/province.model';

export class ProvinceZonesRepositoryMapper extends Mapper<ProvinceZoneEntity, ProvinceZoneModel> {
  mapFrom(param: ProvinceZoneEntity): ProvinceZoneModel {
    const districtMapper = new ProvinceZoneDistrictsRepositoryMapper();
    return {
      zoneId: param.zoneId,
      zoneName: {
        english: param.zoneName.en,
        arabic: param.zoneName.ar,
      },
      maxETA: param.maxETA,
      minETA: param.minETA,
      districts: param.districts?.map(districtMapper.mapFrom),
    };
  }

  mapTo(param: ProvinceZoneModel): ProvinceZoneEntity {
    throw new Error('Method not implemented.');
  }
}
